<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title></v-btn>
      <v-btn color="primary" v-if="getAccess('investor.create')" @click="createNew()" title="Создать элемент">
        <btn-title icon="far fa-plus-square">Создать</btn-title></v-btn
      >
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit" :api="url"></edit-dialog>
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  components: {
    editDialog: () => import("./dialogs/investorDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.investors.list,
      m: this.$store.getters["config/get"].models.investors,
      url: "/mechti/investors",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Инвесторы";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.$router.push({ name: "investors_view", params: { id: d.row.id } });
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
